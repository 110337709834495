import React from "react";
import { Box } from "@mui/material";
import StartMenu from "./layouts/StartMenu";
import Content from "./layouts/Content";
import Router from "./Router";
import { useAuth } from "../hooks/auth";

function HomePage() {
  const { isAuthenticated } = useAuth();

  return (
    <Box data-testid="homepage">
      {isAuthenticated ? (
        <Content>
          <Router />
        </Content>
      ) : (
        <StartMenu />
      )}
    </Box>
  );
}

export default HomePage;
