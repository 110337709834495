import { createSlice } from "@reduxjs/toolkit";

export const syncingSlice = createSlice({
  name: "syncing",
  initialState: {
    isSyncing: false,
    lastSynced: null,
    daysBeforeDeletion: 30,
  },
  reducers: {
    updateSyncing: (state, action) => {
      state.isSyncing = action.payload;
    },
    updateLastSynced: (state, action) => {
      state.lastSynced = action.payload;
    },
    // Optional: Reducer to update daysBeforeDeletion
    updateDaysBeforeDeletion: (state, action) => {
      state.daysBeforeDeletion = action.payload;
    },
    resetSyncingState: (state) => {
      state.isSyncing = false;
      state.lastSynced = null;
      // Keep daysBeforeDeletion as it is a configuration rather than a state
    },
  },
});

export const { updateSyncing, updateLastSynced, updateDaysBeforeDeletion, resetSyncingState } = syncingSlice.actions;

export default syncingSlice.reducer;
