import Dexie from "dexie";

const db = new Dexie("LlibraFlashCardsDB");

db.version(2).stores({
  decks:
    "id, name, description, createdOn, modifiedOn, deletedOn, *cards, createdBy, *owners, *viewers, *editors, isDeleted, defaultInterval, defaultEaseFactor, reviewAt, masteryLevel, progress, lastActive, reviewSettings",
  cards:
    "id, deckId, sideA, sideB, createdOn, modifiedOn, deletedOn, reviewedOn, reviewAt, notes, interval, easeFactor, repetitions, flag, createdBy, isDeleted",
  sessions: "id, deckId, startedOn, endedOn,  *cards, createdBy",
});

export default db;

// easeFactor: Adjusts based on user's rating of card difficulty.
// interval: Time until the card should be reviewed again.
// repetitions: Increments with each review.
