import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily:
      '"Inclusive Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    brand: {
      fontFamily: '"Inclusive Sans", Arial, sans-serif',
      fontWeight: 600,
      fontSize: 32,
      textAlign: "center",
    },
    h1: {
      fontFamily: '"Inclusive Sans", Arial, sans-serif',
      fontWeight: 600,
    },
    h2: {
      fontFamily: '"Inclusive Sans", Arial, sans-serif',
    },
  },
  palette: {
    mode: "light",
    primary: {
      50: "hsl(221.05, 24.05%, 80%)",
      100: "hsl(221.05, 24.05%, 70%)",
      200: "hsl(221.05, 24.05%, 60%)",
      300: "hsl(221.05, 24.05%, 50%)",
      400: "hsl(221.05, 24.05%, 40%)",
      500: "hsl(221.05, 24.05%, 30%)",
      600: "hsl(221.05, 24.05%, 20%)",
      700: "hsl(221.05, 24.05%, 15%)",
      800: "hsl(221.05, 24.05%, 10%)",
      main: "#0A0A0A",
    },

    secondary: {
      50: "hsl(255, 100%, 95%)",
      100: "hsl(255, 100%, 90%)",
      200: "hsl(255, 100%, 80%)",
      300: "hsl(255, 100%, 70%)",
      400: "hsl(255, 100%, 60%)",
      500: "hsl(255, 100%, 50%)",
      600: "hsl(255, 100%, 40%)",
      main: "#2563EB",
      800: "hsl(255, 100%, 20%)",
      900: "hsl(255, 100%, 10%)",
    },
    error: {
      50: "hsl(358.95, 100%, 95%)",
      100: "hsl(358.95, 100%, 90%)",
      200: "hsl(358.95, 100%, 80%)",
      300: "hsl(358.95, 100%, 70%)",
      400: "hsl(358.95, 100%, 60%)",
      500: "hsl(358.95, 100%, 50%)",
      main: "#DC2626",
      700: "hsl(358.95, 100%, 30%)",
      800: "hsl(358.95, 100%, 20%)",
      900: "hsl(358.95, 100%, 10%)",
    },
    warning: {
      50: "hsl(35.81, 100%, 95%)",
      100: "hsl(35.81, 100%, 90%)",
      200: "hsl(35.81, 100%, 80%)",
      300: "hsl(35.81, 100%, 70%)",
      400: "hsl(35.81, 100%, 60%)",
      main: "#D97706",
      600: "hsl(35.81, 100%, 40%)",
      700: "hsl(35.81, 100%, 30%)",
      800: "hsl(35.81, 100%, 20%)",
      900: "hsl(35.81, 100%, 10%)",
    },
    success: {
      50: "hsl(166.72, 100%, 95%)",
      100: "hsl(166.72, 100%, 90%)",
      200: "hsl(166.72, 100%, 80%)",
      300: "hsl(166.72, 100%, 70%)",
      400: "hsl(166.72, 100%, 60%)",
      500: "hsl(166.72, 100%, 50%)",
      600: "hsl(166.72, 100%, 40%)",
      main: "#16A34A",
      800: "hsl(166.72, 100%, 20%)",
      900: "hsl(166.72, 100%, 10%)",
    },
    info: {
      50: "hsl(259.1, 100%, 95%)",
      100: "hsl(259.1, 100%, 90%)",
      200: "hsl(259.1, 100%, 80%)",
      300: "hsl(259.1, 100%, 70%)",
      400: "hsl(259.1, 100%, 65%)",
      main: "#7C3AED",
      600: "hsl(259.1, 100%, 40%)",
      700: "hsl(259.1, 100%, 30%)",
      800: "hsl(259.1, 100%, 20%)",
      900: "hsl(259.1, 100%, 10%)",
    },
    gray: {
      50: "hsl(0, 0%, 98%)",
      100: "hsl(0, 0%, 95%)",
      200: "hsl(0, 0%, 90%)",
      300: "hsl(0, 0%, 80%)",
      400: "hsl(0, 0%, 70%)",
      main: "#A3A3A3",
      600: "hsl(0, 0%, 50%)",
      700: "hsl(0, 0%, 40%)",
      800: "hsl(0, 0%, 30%)",
      900: "hsl(0, 0%, 20%)",
    },
    white: {
      50: "hsl(0, 0%, 100%)",
      100: "hsl(0, 0%, 98%)",
      200: "hsl(0, 0%, 96%)",
      300: "hsl(0, 0%, 94%)",
      400: "hsl(0, 0%, 92%)",
      main: "hsl(0, 0%, 90%)",
      600: "hsl(0, 0%, 88%)",
      700: "hsl(0, 0%, 86%)",
      800: "hsl(0, 0%, 84%)",
      900: "hsl(0, 0%, 82%)",
    },

    text: {
      primary: "#0A0A0A",
      secondary: "hsl(222, 9%, 41%)",
      light: "hsl(219, 10%, 52%)",
      dark: "hsl(240, 0%, 58%)",
      disabled: "hsl(0, 0%, 63%)",
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(7px)",
          WebkitBackdropFilter: "blur(7px)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(to right bottom, hsl(40, 100%, 86%), hsl(38, 100%, 85%), hsl(37, 100%, 84%), hsl(35, 100%, 83%), hsl(33, 100%, 82%), hsl(31, 98%, 81%));",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Inclusive Sans", sans-serif',
          textTransform: "none",
          borderRadius: ".5rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: ".5rem",
        },
      },
      variants: [
        {
          props: { variant: "board" },
          style: {
            boxShadow:
              "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
          },
        },
        {
          props: { variant: "list" },
          style: {
            boxShadow:
              "0px 3px 5px -2px rgba(0, 0, 0, 0.075), 0px 1px 2px -1px rgba(0, 0, 0, 0.04)",
          },
        },
      ],
    },
    MuiListItemButton: {
      variants: [
        {
          props: { variant: "menu" },
          style: {
            paddingRight: "0",
            paddingLeft: "0",
          },
        },
      ],
    },
    MuiListItemText: {
      variants: [
        {
          props: { variant: "menu" },
          style: {
            marginLeft: "25px",
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // Apply a default style that might be common for all variants if necessary
        },
      },
      variants: [
        {
          props: { variant: "board" },
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
            "&:before": {
              display: "none",
            },
            "&.Mui-expanded": {
              margin: 0,
            },
          },
        },
      ],
    },
  },
});

export default theme;
