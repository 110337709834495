import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import cloudStorageReducer from "../redux/features/drive/cloudStorageSlice";
import authReducer from "../redux/features/auth/authSlice";
import deckReducer from "../redux/features/deck/deckSlice";
import screenReducer from "./features/screen/screenSlice";
import syncingReducer from "./features/syncing/syncingSlice";
import errorReducer from "./features/error/errorSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "cloudStorage", "deck", "screen", "syncing"], // Stores being saved to localStorage
};

// Combine reducers into a single reducer function
const combinedReducer = combineReducers({
  auth: authReducer,
  cloudStorage: cloudStorageReducer,
  deck: deckReducer,
  screen: screenReducer,
  syncing: syncingReducer,
  error: errorReducer,
});

// Apply persistReducer to the combined reducer
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

const persistor = persistStore(store);

export default store;
export { persistor };
