import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import StyleRoundedIcon from "@mui/icons-material/StyleRounded";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import db from "../../database/db";
import {
  createCard,
  findAllCardsByDeckId,
  updateCard,
} from "../../services/data/cardDataService";
import { findDeckById, updateDeck } from "../../services/data/deckDataService";
import BreadcrumbsNav from "../../components/common/BreadcrumbsNav";
import CardsScreenMenu from "../../components/menus/CardsScreenMenu";
import CreateCard from "../../components/dialogs/CreateCard";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import ReviewCards from "../../components/dialogs/ReviewCards";
import StudyCards from "../../components/dialogs/StudyCards";
import UpdateCard from "../../components/dialogs/UpdateCard";
import UpdateDeck from "../../components/dialogs/UpdateDeck";
import { navigateTo } from "../../redux/features/screen/screenSlice";
import DropdownMainMenu from "../../components/menus/DropdownMainMenu";
import { setError } from "../../redux/features/error/errorSlice";

const MyCardsScreen = () => {
  const dispatch = useDispatch();
  const deckId = useSelector((state) => state.deck.currentDeckId);
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const daysBeforeDeletion = useSelector(
    (state) => state.syncing.daysBeforeDeletion
  );
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const lastRefreshed = useSelector((state) => state.deck.lastRefreshed);
  const isSyncing = useSelector((state) => state.syncing.isSyncing);
  const [deck, setDeck] = useState(null);
  const [cards, setCards] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [openCreateCardDialog, setOpenCreateCardDialog] = useState(false);
  const [openUpdateDeckDialog, setOpenUpdateDeckDialog] = useState(false);
  const [openUpdateCardDialog, setOpenUpdateCardDialog] = useState(false);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [openStudyDialog, setOpenStudyDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [cardCount, setCardCount] = useState(null);
  const [card, setCard] = useState(null);
  const [todaysCards, setTodaysCards] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const globalDeckDetails = useSelector(
    (state) => state.deck.currentDeckDetails
  );

  const stripHtmlTags = (input) => {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    if (globalDeckDetails) {
      setDeck(globalDeckDetails);
    }
  }, [globalDeckDetails]);

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const handleCloseStudyDialog = async () => {
    setOpenStudyDialog(false);
    // Fetch and update the state of cards and deck
    try {
      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);

      const updatedDeck = await findDeckById(deckId);
      if (updatedDeck) {
        setDeck(updatedDeck);
      }
    } catch (error) {
      console.error(
        "Error occurred while fetching and updating cards and deck:",
        error
      );
    }
  };

  const handleUpdateDeck = async (updatedFields) => {
    if (!deckId) {
      return;
    }

    try {
      const updatedDeck = await updateDeck(deckId, updatedFields);
      if (updatedDeck) {
        setDeck(updatedDeck);
      }
    } catch (error) {
      console.error("Error occurred while updating deck:", error);
    } finally {
      setOpenUpdateDeckDialog(false);
      setForceRefresh((prev) => !prev);
    }
  };

  const handleCreateCard = () => {
    setCard(null);
    setOpenCreateCardDialog(true);
  };

  const handleCloseCreateCardDialog = () => {
    setOpenCreateCardDialog(false);
  };

  const handleOpenUpdateDeckDialog = () => {
    setOpenUpdateDeckDialog(true);
  };

  const handleCloseUpdateDeckDialog = async () => {
    setOpenUpdateDeckDialog(false);
    // Fetch and update the state of cards and deck
    try {
      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);

      const updatedDeck = await findDeckById(deckId);
      if (updatedDeck) {
        setDeck(updatedDeck);
      }
    } catch (error) {
      console.error(
        "Error occurred while fetching and updating cards and deck:",
        error
      );
    }
  };

  const toggleEdit = () => {
    setIsEditClicked((prev) => !prev);
  };

  const handleCardSelect = (cardId) => {
    const selected = cards.find((card) => card.id === cardId);
    if (selected) {
      setCard(selected);
      setOpenUpdateCardDialog(true);
    }
  };

  const handleUpdateCard = async (sideA, sideB) => {
    if (!card?.id) {
      return;
    }

    try {
      const updatedCardData = {
        ...card,
        sideA,
        sideB,
        modifiedOn: new Date().toISOString(),
      };

      await updateCard(card.id, updatedCardData);

      // Update the deck's modifiedOn date after updating the card
      const deckUpdatePayload = {
        modifiedOn: new Date().toISOString(),
      };
      await updateDeck(deckId, deckUpdatePayload);

      // Fetch and update the state of cards and deck
      try {
        const updatedCards = await findAllCardsByDeckId(deckId);
        setCards(updatedCards);

        const updatedDeck = await findDeckById(deckId);
        if (updatedDeck) {
          setDeck(updatedDeck);
        }
      } catch (error) {
        console.error(
          "Error occurred while fetching and updating cards and deck:",
          error
        );
      }
    } catch (error) {
      console.error("Error occurred while updating card:", error);
    } finally {
      setOpenUpdateCardDialog(false);
      setForceRefresh((prev) => !prev);
    }
  };

  const handleCloseUpdateCardDialog = () => {
    setOpenUpdateCardDialog(false);
  };

  // Fetch Deck
  useEffect(() => {
    const fetchDeck = async () => {
      if (!deckId) {
        return;
      }

      try {
        const fetchedDeck = await findDeckById(deckId);
        if (fetchedDeck) {
          setDeck(fetchedDeck);
        } else {
          console.error("Deck not found");
        }
      } catch (error) {
        dispatch(setError("Couldn't load deck. Please try again"));
        console.error("ERROR: ", error);
      }
    };
    fetchDeck();
  }, [deckId]);

  // Fetch Cards
  useEffect(() => {
    const getCards = async () => {
      if (!deckId) {
        return;
      }

      try {
        const loadedCards = await findAllCardsByDeckId(deckId);
        setCards(loadedCards);
        setCardCount(loadedCards.length);
      } catch (error) {
        dispatch(
          setError("An issue occurred while getting cards. Please try again")
        );
        console.error("ERROR: ", error);
      } finally {
        setIsLoading(false);
        setInitialLoad(false);
      }
    };
    getCards();
  }, [deckId, lastSynced]);

  // Setup periodic sync and initial fetch for cards due
  useEffect(() => {
    const refreshIntervalMinutes = 1; // Set the desired interval in minutes

    const fetchAndFilterDueCards = async () => {
      try {
        const allCards = await findAllCardsByDeckId(deckId);
        const now = new Date();
        const dueCards = allCards.filter((card) => {
          const reviewDate = new Date(card.reviewAt);
          return reviewDate <= now;
        });
        setTodaysCards(dueCards); // Update the todaysCards state
      } catch (error) {
        console.error("Error fetching and filtering due cards:", error);
      }
    };

    fetchAndFilterDueCards();

    // Calculate the interval in milliseconds from minutes
    const intervalMs = refreshIntervalMinutes * 60 * 1000;

    // Set up an interval to trigger the refresh
    const intervalId = setInterval(() => {
      setForceRefresh((prev) => !prev); // Toggle the forceRefresh state to trigger the effect
    }, intervalMs);

    return () => clearInterval(intervalId);
  }, [deckId, lastRefreshed, forceRefresh]);

  // CREATE Card
  const handleSubmitCard = async (sideA, sideB) => {
    if (!deckId) {
      return;
    }
    // Check if both sideA and sideB are empty
    if (!sideA.trim() && !sideB.trim()) {
      return;
    }
    if (!userId) {
      return;
    }
    try {
      const cardData = {
        sideA,
        sideB,
        deckId,
        createdBy: userId,
      };

      // Create a new card
      const cardId = await createCard(cardData, deckId, userId);

      // Prepare update payload for the deck
      const updatePayload = {
        cards: deck?.cards ? [...deck.cards, cardId] : [cardId],
      };

      // Update the deck
      const updatedDeck = await updateDeck(deckId, updatePayload);

      // Update local state for deck
      setDeck(updatedDeck);
      // Update local state for cards (add the full card object)
      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);
      setCardCount(updatedCards.length);
    } catch (error) {
      dispatch(
        setError("An issue occurred while creating card. Please try again")
      );
      console.error("ERROR: ", error);
    } finally {
      handleCloseCreateCardDialog();
      setForceRefresh((prev) => !prev);
    }
  };

  // SOFT DELETE Cards
  const handleSoftDelete = async (event, cardId) => {
    try {
      event.stopPropagation();

      const card = await db.cards.get(cardId);

      if (!card) {
        return;
      }

      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);

      card.deletedOn = deletionDate.toISOString();
      card.modifiedOn = now.toISOString();

      await db.cards.put(card);

      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);
      setCardCount(updatedCards.length);
    } catch (error) {
      dispatch(
        setError("An issue occurred while deleting card. Please try again")
      );
      console.error("ERROR: ", error);
    } finally {
      setForceRefresh((prev) => !prev);
    }
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 35);

  const cardItemText = (card) => {
    const charLimit = getCharLimit();
    const cleanText = stripHtmlTags(card.sideA); // Strip HTML from sideA
    return (
      <Typography variant="h2" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
        {cleanText.length > charLimit
          ? `${cleanText.substring(0, charLimit)}...`
          : cleanText}
      </Typography>
    );
  };

  const cardSecondaryText = (card) => {
    const reviewAtFormatted = card.reviewAt ? formatDate(card.reviewAt) : "";
    const cleanText = stripHtmlTags(card.sideB); // Strip HTML from sideB
    const sideBText =
      cleanText.length > getCharLimit()
        ? `${cleanText.substring(0, getCharLimit())}...`
        : cleanText;

    return (
      <>
        {reviewAtFormatted && (
          <Typography
            component="span"
            variant="body2"
            color="primary"
            sx={{ fontSize: { xs: ".8rem", sm: ".9rem" } }}
          >
            {reviewAtFormatted}
          </Typography>
        )}
        <Typography
          component="span"
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: ".8rem", sm: ".9rem" },
            marginLeft: reviewAtFormatted ? 1 : 0,
          }}
        >
          {sideBText}
        </Typography>
      </>
    );
  };

  const formatDate = (reviewAt) => {
    const reviewDate = new Date(reviewAt);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (reviewDate.toDateString() === today.toDateString()) {
      return "Due Today";
    } else if (reviewDate.toDateString() === yesterday.toDateString()) {
      return "Due Yesterday";
    } else if (reviewDate.toDateString() === tomorrow.toDateString()) {
      return "Due Tomorrow";
    } else {
      return reviewDate.toLocaleDateString();
    }
  };

  const cardDueSecondaryText = (card) => {
    // const sideBText = card.sideB.length > getCharLimit() ? `${card.sideB.substring(0, getCharLimit())}...` : card.sideB;

    return (
      <Typography
        component="span"
        variant="body2"
        color="text.secondary"
        sx={{ fontSize: { xs: ".8rem", sm: ".9rem" }, marginLeft: 0 }}
      >
        ...
      </Typography>
    );
  };

  const cardSecondaryAction = (card) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton
          edge="end"
          aria-label="more"
          onClick={(e) => handleSoftDelete(e, card.id)}
        >
          <DeleteOutlineRoundedIcon color="primary" />
        </IconButton>
      </Stack>
    </Fade>
  );

  return (
    <Stack data-testid="mycards-screen" spacing={1.5}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" alignItems="center" sx={{ ml: -1 }}>
          <BreadcrumbsNav />
        </Box>
        <Stack direction="row" spacing={1}>
          <IconButton
            color="primary"
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.1rem",
              },
              color: "text.primary",
              borderRadius: 1.5,
            }}
            onClick={handleCreateCard}
          >
            <AddBoxOutlinedIcon />
          </IconButton>
          <Stack direction="row" spacing={2} alignItems="center">
            {isSmallScreen && <DropdownMainMenu />}

            <Typography
              color="primary"
              onClick={toggleEdit}
              sx={{
                cursor: "pointer",
                color: "text.primary",
              }}
            >
              {isEditClicked ? "Done" : "Edit"}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box>
        <CardsScreenMenu
          isEditClicked={isEditClicked}
          deck={deck}
          handleUpdateDeck={handleOpenUpdateDeckDialog}
          navigateTo={navigateTo}
          dispatch={dispatch}
          onReviewClick={handleCloseReviewDialog}
          onStudyClick={handleCloseStudyDialog}
        />
      </Box>

      <Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Button
              // startIcon={<AddRoundedIcon />}
              color="primary"
              size="small"
              variant="outlined"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
              }}
              onClick={() => setOpenReviewDialog(true)}
            >
              Review
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              size="small"
              variant="contained"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                // color: "text.primary",
              }}
              onClick={() => setOpenStudyDialog(true)}
            >
              Study
            </Button>
          </Box>
        </Box>
      </Stack>

      <Stack spacing={3} sx={{ pt: 2 }}>
        {cards.length > 0 ? (
          <>
            {todaysCards.length > 0 && (
              <Stack spacing={2}>
                <Accordion defaultExpanded disableGutters variant="board">
                  <AccordionSummary
                    expandIcon={<ExpandMoreRoundedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h1"
                      color="text.primary"
                      sx={{
                        fontSize: { xs: "1.2rem", sm: "1.2rem", lg: "1.5rem" },
                      }}
                    >
                      Cards Due
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack data-testid="mycards-screen" spacing={1.5}>
                      <PaperList
                        items={todaysCards}
                        onItemSelect={handleCardSelect}
                        isEditClicked={isEditClicked}
                        secondaryAction={cardSecondaryAction}
                        itemText={cardItemText}
                        itemSecondaryText={cardDueSecondaryText}
                        showForwardIcon={false}
                        itemHeight="4rem"
                        dividerInsetMargin="1rem"
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            )}

            <Stack spacing={2}>
              <Accordion defaultExpanded disableGutters variant="board">
                <AccordionSummary
                  expandIcon={<ExpandMoreRoundedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="h1"
                    color="text.primary"
                    sx={{ fontSize: { xs: "1.2rem", lg: "1.5rem" } }}
                  >
                    All
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack data-testid="allcards-screen" spacing={1.5}>
                    <PaperList
                      items={cards}
                      onItemSelect={handleCardSelect}
                      isEditClicked={isEditClicked}
                      secondaryAction={cardSecondaryAction}
                      itemText={cardItemText}
                      itemSecondaryText={cardSecondaryText}
                      showForwardIcon={false}
                      itemHeight="4rem"
                      dividerInsetMargin="1rem"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </>
        ) : (
          <PaperListPlaceholder
            icon={StyleRoundedIcon}
            primaryText="No Cards found"
            secondaryText="Create a new card to get started."
          />
        )}
      </Stack>

      <CreateCard
        open={openCreateCardDialog}
        onClose={handleCloseCreateCardDialog}
        onSubmit={handleSubmitCard}
        card={card}
      />
      <UpdateDeck
        open={openUpdateDeckDialog}
        onClose={handleCloseUpdateDeckDialog}
        onSubmit={handleUpdateDeck}
        deckId={deckId}
      />
      <UpdateCard
        open={openUpdateCardDialog}
        onClose={handleCloseUpdateCardDialog}
        onSubmit={handleUpdateCard}
        card={card}
      />
      <ReviewCards
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        deckId={deckId}
        cards={cards}
      />
      <StudyCards
        open={openStudyDialog}
        onClose={handleCloseStudyDialog}
        deckId={deckId}
        cards={cards}
      />
    </Stack>
  );
};

export default MyCardsScreen;
