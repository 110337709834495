import React from "react";
import { useSelector } from "react-redux";
import MyDecksScreen from "../pages/screens/MyDecksScreen";
import MyCardsScreen from "../pages/screens/MyCardsScreen";
import CardsBinScreen from "../pages/screens/CardsBinScreen";
import DecksBinScreen from "../pages/screens/DecksBinScreen";

const screenComponents = {
  MyDecks: MyDecksScreen,
  MyCards: MyCardsScreen,
  DecksBin: DecksBinScreen,
  CardsBin: CardsBinScreen,
};

function Router() {
  const currentScreen = useSelector((state) => state.screen.currentScreen);
  const ScreenComponent = screenComponents[currentScreen] || MyDecksScreen;

  return <ScreenComponent />;
}

export default Router;
