import React from "react";
import { useDispatch } from "react-redux";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import TimerIcon from "@mui/icons-material/Timer";
import QuizIcon from "@mui/icons-material/Quiz";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { clearDb } from "../../utilities/db";
import { navigateTo } from "../../redux/features/screen/screenSlice";
import { resetCloudStorageState } from "../../redux/features/drive/cloudStorageSlice";
import { resetDeckState } from "../../redux/features/deck/deckSlice";
import { resetHistoryOnSignOut } from "../../redux/features/screen/screenSlice";
import { resetSyncingState } from "../../redux/features/syncing/syncingSlice";
import { signOut } from "../../redux/features/auth/authSlice";
import { setError } from "../../redux/features/error/errorSlice";

const DropdownMainMenu = () => {
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      dispatch(signOut());
      dispatch(resetHistoryOnSignOut());
      dispatch(resetDeckState());
      dispatch(resetCloudStorageState());
      dispatch(resetSyncingState());
      clearDb();
    } catch (error) {
      dispatch(
        setError("An error occurred while signing out. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToDecks = () => {
    try {
      dispatch(navigateTo("MyDecks"));
    } catch (error) {
      dispatch(
        setError(
          "An error occurred while navigating to decks. Please try again."
        )
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToMarketing = () => {
    try {
      window.open("https://llibra.io/blog", "_blank");
    } catch (error) {
      dispatch(
        setError("An error occurred while navigating. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToPracticeTest = () => {
    try {
      window.open(
        "https://llibra.powerappsportals.com/library/practice-tests/",
        "_blank"
      );
    } catch (error) {
      dispatch(
        setError("An error occurred while navigating. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToQuizzes = () => {
    try {
      window.open(
        "https://llibra.powerappsportals.com/library/quizzes/",
        "_blank"
      );
    } catch (error) {
      dispatch(
        setError("An error occurred while navigating. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleSupport = () => {
    window.location.href = "mailto:support@llibra.io";
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack data-testid="mobile-menu">
      <IconButton
        id="menu-button"
        aria-controls={open ? "mobile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="primary"
      >
        <MoreHorizRoundedIcon />
      </IconButton>
      <Menu
        id="mobile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              minWidth: "12rem",
            },
          },
        }}
      >
        <MenuItem onClick={handleNavigateToDecks}>
          <ListItemText>My Decks</ListItemText>
          <ListItemIcon>
            <LayersRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleNavigateToPracticeTest}>
          <ListItemText>Practice Tests</ListItemText>
          <ListItemIcon>
            <TimerIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleNavigateToQuizzes}>
          <ListItemText>Quizzes</ListItemText>
          <ListItemIcon>
            <QuizIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleNavigateToMarketing}>
          <ListItemText>Discover</ListItemText>
          <ListItemIcon>
            <FiberNewRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSupport}>
          <ListItemText>Support</ListItemText>
          <ListItemIcon>
            <HelpOutlineRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemText>Sign Out</ListItemText>
          <ListItemIcon>
            <ExitToAppRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default DropdownMainMenu;
