import db from "../../database/db";
import { v4 as uuidv4 } from "uuid";

export const createCard = async (card, deckId, userId) => {
  try {
    const now = new Date().toISOString();
    const currentDeck = await db.decks.get(deckId);
    if (!currentDeck) {
      console.error("Deck not found:", deckId);
      return null;
    }

    // Check if a card with the same sides already exists in the deck and is not deleted
    const existingCard = await db.cards
      .where({ deckId: deckId })
      .and(
        (c) => c.sideA === card.sideA && c.sideB === card.sideB && !c.isDeleted
      )
      .first();

    if (existingCard) {
      return existingCard.id;
    }

    // Initialize new card with properties
    card.id = uuidv4();
    card.deckId = deckId;
    card.sideA = card.sideA || "";
    card.sideB = card.sideB || "";
    card.createdOn = now;
    card.createdBy = userId;
    card.modifiedOn = now;
    card.reviewAt = now;
    card.notes = card.notes || "";
    card.easeFactor =
      card.easeFactor !== undefined
        ? card.easeFactor
        : currentDeck.easeFactor || 1.3;
    card.interval =
      card.interval !== undefined
        ? card.interval
        : currentDeck.interval || 0.01;
    card.difficultyRating = card.difficultyRating || 0;
    card.repetitions = card.repetitions || 0;
    card.flag = card.flag || false;
    card.isDeleted = false;
    card.deletedOn = null;
    card.syncedOn = null;

    // Add the new card to the database
    const cardId = await db.cards.add(card);

    // Append the new card ID to the deck's cards array
    const updatedCardsArray = currentDeck.cards
      ? [...currentDeck.cards, cardId]
      : [cardId];

    // Update the deck with the new cards array
    await db.decks.update(deckId, { cards: updatedCardsArray });

    return cardId;
  } catch (error) {
    console.error("Error creating card:", error);
    return null;
  }
};

export const findAllCardsByDeckId = async (deckId, includeDeleted = false) => {
  if (!deckId) return [];

  try {
    const filterCondition = includeDeleted
      ? (card) =>
          card.deckId === deckId && !card.isDeleted && card.deletedOn !== null
      : (card) =>
          card.deckId === deckId &&
          !card.isDeleted &&
          (card.deletedOn === undefined || card.deletedOn === null);

    const cards = await db.cards.filter(filterCondition).toArray();

    cards.sort((a, b) => a.sideA.localeCompare(b.sideA));

    return cards;
  } catch (error) {
    console.error("Error loading cards from IndexedDB:", error);
  }
};

export const updateCard = async (cardId, updates) => {
  if (!cardId || !updates) {
    console.error("Missing cardId or updates parameter");
    return null;
  }

  try {
    const card = await db.cards.get({ id: cardId });
    if (!card) {
      console.error("Card not found:", cardId);
      return null;
    }

    const updatedFields = {
      ...updates,
      modifiedOn: new Date().toISOString(),
    };

    await db.cards.update(cardId, updatedFields);
    return await db.cards.get({ id: cardId });
  } catch (error) {
    console.error("Error updating card in IndexedDB:", error);
    return null;
  }
};

export const findCardById = async (cardId) => {
  try {
    const card = await db.cards.get({ id: cardId });
    if (!card) {
      console.error("Card not found:", cardId);
      return null;
    }
    return card;
  } catch (error) {
    console.error("Error retrieving card from IndexedDB:", error);
    return null;
  }
};
