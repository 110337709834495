import { createSlice } from "@reduxjs/toolkit";

export const errorSlice = createSlice({
  name: "error",
  initialState: {
    message: "",
    showError: false,
  },
  reducers: {
    setError: (state, action) => {
      state.message = action.payload;
      state.showError = true;
    },
    clearError: (state) => {
      state.message = "";
      state.showError = false;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
