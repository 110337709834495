import React, { useState } from "react";
import { Button, ListItemIcon, ListItemText, Menu, Stack, Typography } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const ListDropdownMenu = ({ title, children, color = "text.primary" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack data-testid="list-dropdown-menu" direction="row">
        <Button
          size="small"
          onClick={handleClick}
          endIcon={<ExpandMoreRoundedIcon sx={{ color }} />}
          sx={{
            textTransform: "none",
            justifyContent: "start",
          }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: "1.75rem",
              fontWeight: "600",
              maxWidth: {
                xs: "300px",
                sm: "500px",
              },
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              color: color,
            }}>
            {title}
          </Typography>
        </Button>
      </Stack>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              minWidth: "12rem",
            },
          },
        }}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: () => {
              handleClose();
              child.props.onClick?.();
            },
            ...(child.type === ListItemIcon && {
              sx: { fontSize: { xs: "1.6rem", lg: "2rem" } },
            }),
            ...(child.type === ListItemText && {
              primaryTypographyProps: {
                variant: "body2",
                sx: { fontSize: { xs: "1.6rem", lg: "2rem" } },
              },
            }),
          })
        )}
      </Menu>
    </div>
  );
};

export default ListDropdownMenu;
