import React from "react";
import { Stack, Typography } from "@mui/material";

const BrandTitle = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
      spacing={2}
      data-testid="brand-title"
      sx={{ mt: 4 }}
    >
      <img
        src="/logo.png"
        alt="Logo"
        style={{ maxWidth: 150, height: "auto" }}
      />
      <Typography
        variant="h6"
        component="h1"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "medium",
          color: "text.primary",
        }}
      >
        FlashCards
      </Typography>
    </Stack>
  );
};

export default BrandTitle;
