import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";

const isSmallScreen = () => window.innerWidth < 600;

const getCharLimit = () => (isSmallScreen() ? 25 : 55);

const DeleteCard = ({ open, onClose, onDelete, cardName }) => {
  const truncateCardName = (name) => {
    const charLimit = getCharLimit();
    return name.length > charLimit
      ? name.substring(0, charLimit) + "..."
      : name;
  };
  return (
    <Dialog
      data-testid="delete-card-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="delete-card-dialog-title"
      aria-describedby="delete-card-dialog-description"
      PaperProps={{
        sx: {
          marginTop: { xs: -25, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          height: { xs: "auto", sm: "auto", md: "auto" },
          borderRadius: 3,
        },
      }}
    >
      <DialogContent>
        <Typography
          variant="h5"
          id="delete-card-dialog-description"
          sx={{ fontWeight: 600, wordBreak: "break-word" }}
        >
          Delete Card
        </Typography>
        <Typography variant="body1" id="delete-card-dialog-description">
          Are you sure you want to delete this card? This action cannot be
          undone.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "24px 24px 10px 24px", flexShrink: 0 }}>
        <Button onClick={onDelete} variant="contained" color="error" fullWidth>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose} fullWidth>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCard;
