import React from "react";
import { useDispatch } from "react-redux";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSync, useSyncStatus } from "../../../hooks/sync";
import SyncIcon from "./SyncIcon";
import Signin from "../../dialogs/SignIn";
import { isTokenValid } from "../../../utilities/validateToken";
import { setError } from "../../../redux/features/error/errorSlice";

const SyncButton = ({ listItemStyle = {}, buttonStyle = {}, externalIconStyle = {} }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { tokenExpiration, isSyncing, openSignInDialog, setOpenSignInDialog } = useSyncStatus();
  const sync = useSync();

  const handleSync = async () => {
    try {
      if (isTokenValid(tokenExpiration)) {
        sync();
      } else {
        setOpenSignInDialog(true);
      }
    } catch (error) {
      dispatch(setError("An issue occurred while trying to sync. Please try again"));
      console.error("ERROR: ", error);
    }
  };

  const handleCloseSignIn = () => {
    setOpenSignInDialog(false);
  };

  const defaultIconStyle = isMobile ? { marginRight: 0, marginLeft: "auto", display: "flex", justifyContent: "center" } : {};
  const iconStyle = { ...externalIconStyle, ...defaultIconStyle };
  const rotate = {
    "@keyframes rotating": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(-360deg)" },
    },
    animation: "rotating 2s linear infinite",
    transformOrigin: "center",
  };

  const marginLeftValue = isSyncing ? 6 : isTokenValid(tokenExpiration) ? 6 : 3;

  return (
    <>
      <ListItem data-testid="sync-button" disablePadding sx={{ ...listItemStyle }}>
        <ListItemButton variant="menu" onClick={handleSync} sx={{ width: "fit-content", ...buttonStyle }}>
          <ListItemText
            primary={isSyncing ? "Syncing" : isTokenValid(tokenExpiration) ? "Synced" : "Not Syncing"}
            variant="menu"
            sx={{
              "& .MuiListItemText-primary": {
                fontSize: { xs: 10, sm: 10 },
              },
              ml: { xs: marginLeftValue, sm: marginLeftValue + 1 },
            }}
          />
          <SyncIcon isSyncing={isSyncing} tokenExpiration={tokenExpiration} iconStyle={iconStyle} rotate={rotate} />
        </ListItemButton>
      </ListItem>
      <Signin open={openSignInDialog} onClose={handleCloseSignIn} />
    </>
  );
};

export default SyncButton;
