import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import TimerIcon from "@mui/icons-material/Timer";
import QuizIcon from "@mui/icons-material/Quiz";
import { signOut } from "../../redux/features/auth/authSlice";
import { navigateTo } from "../../redux/features/screen/screenSlice";
import SyncButton from "../common/sync/SyncButton";
import { resetDeckState } from "../../redux/features/deck/deckSlice";
import { resetCloudStorageState } from "../../redux/features/drive/cloudStorageSlice";
import { resetHistoryOnSignOut } from "../../redux/features/screen/screenSlice";
import { resetSyncingState } from "../../redux/features/syncing/syncingSlice";
import { clearDb } from "../../utilities/db";
import { setError } from "../../redux/features/error/errorSlice";

function MainMenu() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      dispatch(signOut());
      dispatch(resetHistoryOnSignOut());
      dispatch(resetDeckState());
      dispatch(resetCloudStorageState());
      dispatch(resetSyncingState());
      clearDb();
    } catch (error) {
      dispatch(
        setError("An error occurred while signing out. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToDecks = () => {
    try {
      dispatch(navigateTo("MyDecks"));
    } catch (error) {
      dispatch(
        setError(
          "An error occurred while navigating to decks. Please try again."
        )
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToMarketing = () => {
    try {
      window.open("https://llibra.io/blog", "_blank");
    } catch (error) {
      dispatch(
        setError("An error occurred while navigating. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToPracticeTest = () => {
    try {
      window.open("https://app.llibra.io/library/practice-tests/", "_blank");
    } catch (error) {
      dispatch(
        setError("An error occurred while navigating. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleNavigateToQuizzes = () => {
    try {
      window.open("https://app.llibra.io/", "_blank");
    } catch (error) {
      dispatch(
        setError("An error occurred while navigating. Please try again.")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleSupport = () => {
    window.location.href = "mailto:support@llibra.io";
  };

  const iconStyle = isMobile
    ? {
        marginRight: 0,
        marginLeft: "auto",
        display: "flex",
        justifyContent: "center",
      }
    : {};

  return (
    <Stack
      data-testid="main-menu"
      sx={{
        backgroundColor: theme.palette.gray[200],
        minHeight: {
          xs: "100vh",
          sm: 530,
        },
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ py: 1 }}>
        <SyncButton />
      </Box>

      <Divider />
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <List sx={{ width: "auto" }}>
          <ListItem disablePadding>
            <ListItemButton variant="menu" onClick={handleNavigateToDecks}>
              {!isMobile && <ListItemText primary="My Decks" variant="menu" />}
              <ListItemIcon sx={iconStyle}>
                <LayersRoundedIcon
                  sx={{ fontSize: { xs: "1.3rem", sm: "1.4rem" } }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              variant="menu"
              onClick={handleNavigateToPracticeTest}
            >
              {!isMobile && (
                <ListItemText
                  sx={{ pr: 5 }}
                  primary="Practice Tests"
                  variant="menu"
                />
              )}
              <ListItemIcon sx={iconStyle}>
                <TimerIcon sx={{ fontSize: { xs: "1.3rem", sm: "1.4rem" } }} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton variant="menu" onClick={handleNavigateToQuizzes}>
              {!isMobile && (
                <ListItemText sx={{ pr: 5 }} primary="Quizzes" variant="menu" />
              )}
              <ListItemIcon sx={iconStyle}>
                <QuizIcon sx={{ fontSize: { xs: "1.3rem", sm: "1.4rem" } }} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton variant="menu" onClick={handleNavigateToMarketing}>
              {!isMobile && (
                <ListItemText
                  sx={{ pr: 5 }}
                  primary="Discover"
                  variant="menu"
                />
              )}
              <ListItemIcon sx={iconStyle}>
                <FiberNewRoundedIcon
                  sx={{ fontSize: { xs: "1.3rem", sm: "1.4rem" } }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton variant="menu" onClick={handleSupport}>
              {!isMobile && <ListItemText primary="Support" variant="menu" />}
              <ListItemIcon sx={isMobile ? iconStyle : { ml: 2 }}>
                <HelpOutlineRoundedIcon
                  sx={{ fontSize: { xs: "1.3rem", sm: "1.4rem" } }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton variant="menu" onClick={handleSignOut}>
              {!isMobile && <ListItemText primary="Sign Out" variant="menu" />}
              <ListItemIcon sx={isMobile ? iconStyle : { ml: 2 }}>
                <ExitToAppRoundedIcon
                  sx={{ fontSize: { xs: "1.3rem", sm: "1.4rem" } }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Stack>
  );
}

export default MainMenu;
