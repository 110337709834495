import { createSlice } from "@reduxjs/toolkit";

export const cloudStorageSlice = createSlice({
  name: "cloudStorage",
  initialState: {
    googleParentId: null,
    googleDataId: null,
    googleDataFileId: null,
    // oneDriveFolderId: null,
  },
  reducers: {
    updateGoogleParentId: (state, action) => {
      state.googleParentId = action.payload;
    },
    updateGoogleDataId: (state, action) => {
      state.googleDataId = action.payload;
    },
    updateGoogleDataFileId: (state, action) => {
      state.googleDataFileId = action.payload;
    },
    resetCloudStorageState: (state) => {
      state.googleParentId = null;
      state.googleDataId = null;
      state.googleDataFileId = null;
      // state.oneDriveFolderId = null; // Uncomment when we start using OneDrive
    },

    // Placeholder for OneDrive folder ID update
    // updateOneDriveFolderId: (state, action) => {
    //   state.oneDriveFolderId = action.payload;
    // },
  },
});

export const { updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId, resetCloudStorageState /*, updateOneDriveFolderId */ } = cloudStorageSlice.actions;

export default cloudStorageSlice.reducer;
