import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Fade, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AppDialog from "../common/AppDialog";
import { setError } from "../../redux/features/error/errorSlice";

const ReviewCards = ({ open, onClose, deckId, cards }) => {
  const dispatch = useDispatch();
  const [showBack, setShowBack] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSessionComplete, setIsSessionComplete] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const currentCard = cards[currentCardIndex] || { sideA: "", sideB: "" };

  useEffect(() => {
    const initializeSession = async () => {
      setIsLoading(true);
      setShowBack(false);
      setCurrentCardIndex(0);
      setIsSessionComplete(false);
      setIsLoading(false);
    };

    if (open) {
      try {
        initializeSession();
      } catch (error) {
        dispatch(setError("Umm...something went wrong. Please try refreshing the page"));
        console.error("ERROR: ", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleFlip = () => {
    setShowBack(!showBack);
  };

  const handleNext = () => {
    try {
      if (currentCardIndex < cards.length - 1) {
        setCurrentCardIndex((prevIndex) => prevIndex + 1);
        setShowBack(false);
      }
      if (currentCardIndex === cards.length - 1) {
        setIsSessionComplete(true);
      }
    } catch (error) {
      dispatch(setError("Umm...something went wrong. Please try refreshing the page"));
      console.error("ERROR: ", error);
    }
  };

  const handlePrev = () => {
    try {
      if (isSessionComplete && currentCardIndex === cards.length - 1) {
        setIsSessionComplete(false);
        setCurrentCardIndex(cards.length - 1);
        setShowBack(false);
      } else if (currentCardIndex > 0) {
        setCurrentCardIndex((prevIndex) => prevIndex - 1);
        setShowBack(false);
      }
    } catch (error) {
      dispatch(setError("Umm...something went wrong. Please try refreshing the page"));
      console.error("ERROR: ", error);
    }
  };

  const handleClose = async () => {
    try {
      onClose();
    } catch (error) {
      dispatch(setError("Umm...something went wrong. Please try refreshing the page"));
      console.error("ERROR: ", error);
    }
  };

  return (
    <AppDialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
        <IconButton disabled={currentCardIndex === 0} size="small" onClick={handlePrev}>
          <ArrowBackIosNewRoundedIcon />
        </IconButton>

        <IconButton disabled={currentCardIndex === cards.length - 1} size="small" onClick={handleNext}>
          <ArrowForwardIosRoundedIcon />
        </IconButton>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        onClick={handleFlip}
        sx={{
          cursor: "pointer",
          minHeight: { xs: 0, sm: "200px", lg: "500px" },
          maxHeight: { lg: "600px" },
          // border: "solid",
          // borderColor: "purple",
        }}>
        {isSessionComplete && (currentCardIndex === cards.length - 1 || cards.length === 0) ? (
          <>
            {/* Session Complete Card */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                py: 1,
              }}>
              <Typography variant="h6" sx={{ userSelect: "none", fontSize: 60, textAlign: "center" }}>
                🎉
              </Typography>
              <Typography variant="h6" sx={{ userSelect: "none", textAlign: "center" }}>
                All Caught Up!
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ userSelect: "none", px: 2, mt: 1, textAlign: "center" }}>
                You're all done for now. Cards will reappear as they're ready for review.
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ userSelect: "none", px: 2, mt: 1, textAlign: "center" }}>
                Learn more about{" "}
                <a href="https://en.wikipedia.org/wiki/Spaced_repetition" target="_blank" rel="noopener noreferrer">
                  Spaced Repetition
                </a>
              </Typography>
            </Box>
          </>
        ) : (
          <>
            {/* Active Session Cards */}

            <Box sx={{ overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              {showBack ? (
                <Fade in={true} timeout={300}>
                  <div
                    dangerouslySetInnerHTML={{ __html: currentCard.sideB }}
                    className="ql-editor"
                    style={{
                      width: "100%",
                      padding: "16px",
                      boxSizing: "border-box",
                      wordBreak: "break-word",
                      textAlign: "left",
                      userSelect: "none",
                    }}
                  />
                </Fade>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: currentCard.sideA }}
                  className="ql-editor"
                  style={{
                    width: "100%",
                    padding: "16px",
                    boxSizing: "border-box",
                    wordBreak: "break-word",
                    textAlign: "left",
                    userSelect: "none",
                  }}
                />
              )}
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-around", px: { xs: 16, sm: 0 } }}></DialogActions>
    </AppDialog>
  );
};

export default ReviewCards;
