import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { shuffle } from "../../utilities/card";
import {
  createSession,
  findSessionById,
  updateSessionCard,
} from "../../services/data/sessionDataService";
import { updateCard } from "../../services/data/cardDataService";
import { useSync } from "../../hooks/sync";
import AppDialog from "../common/AppDialog";
import { updateLastRefreshed } from "../../redux/features/deck/deckSlice";
import { setError } from "../../redux/features/error/errorSlice";

const StudyCards = ({ open, onClose, deckId, cards }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const sync = useSync();
  const [showBack, setShowBack] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSessionComplete, setIsSessionComplete] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [session, setSession] = useState([]);
  const [cardsDue, setCardsDue] = useState([]);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const infoOpen = Boolean(infoAnchorEl);
  const currentCard = cardsDue[currentCardIndex] || {};

  // Function to open the Popover
  const handleInfoClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };
  // Function to close the Popover
  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  useEffect(() => {
    const initializeSession = async () => {
      setIsLoading(true);
      setIsReset(false);
      setShowBack(false);
      setCurrentCardIndex(0);
      setIsPaused(false);
      setIsSessionComplete(false);

      const now = new Date();
      const filteredCards = cards.filter(
        (card) => new Date(card.reviewAt) <= now
      );
      const shuffledCards = shuffle(filteredCards);
      if (shuffledCards.length === 0) {
        setIsSessionComplete(true);
      } else {
        try {
          const newSessionId = await createSession(
            deckId,
            shuffledCards,
            userId
          );
          const newSession = await findSessionById(newSessionId);
          setSessionId(newSessionId);
          setCardsDue(newSession.cards);
        } catch (error) {
          dispatch(
            setError(
              "Umm...something went wrong. Please try refreshing the page"
            )
          );
          console.error("ERROR: ", error);
        }
      }
      setIsLoading(false);
    };

    if (open) {
      initializeSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleFlip = () => {
    setShowBack(!showBack);
  };

  const handleNext = () => {
    try {
      if (currentCardIndex < cardsDue.length - 1) {
        setCurrentCardIndex((prevIndex) => prevIndex + 1);
        // Check if the next card has been isReviewed or not to determine 'paused' state
        const nextCardReviewed = cardsDue[currentCardIndex + 1]?.isReviewed;
        setIsPaused(nextCardReviewed === true);
        setShowBack(false);
      }
      if (currentCardIndex === cardsDue.length - 1) {
        setIsSessionComplete(true);
      }
    } catch (error) {
      dispatch(
        setError("Umm...something went wrong. Please try refreshing the page")
      );
      console.error("ERROR: ", error);
    }
  };

  const handlePrev = () => {
    try {
      if (isSessionComplete && currentCardIndex === cardsDue.length - 1) {
        setIsSessionComplete(false);
        setCurrentCardIndex(cardsDue.length - 1);
        setShowBack(false);
        setIsPaused(true);
      } else if (currentCardIndex > 0) {
        setCurrentCardIndex((prevIndex) => prevIndex - 1);
        setShowBack(false);
        setIsPaused(true);
      }
    } catch (error) {
      dispatch(
        setError("Umm...something went wrong. Please try refreshing the page")
      );
      console.error("ERROR: ", error);
    }
  };

  const updateSessionCards = async (sessionCards) => {
    // Filter out cards that have not been reviewed
    const reviewedCards = sessionCards.filter(
      (sessionCard) => sessionCard.isReviewed
    );

    const updatePromises = reviewedCards.map(async (sessionCard) => {
      const { cardId, ...cardUpdates } = sessionCard;

      // Ensure cardId is present before attempting the update
      if (!cardId) {
        console.error("Missing cardId while updating reviewed session cards.");
        return null;
      }

      try {
        return await updateCard(cardId, {
          reviewAt: cardUpdates.reviewAt,
          easeFactor: cardUpdates.easeFactor,
          interval: cardUpdates.interval,
          difficultyRating: cardUpdates.difficultyRating,
          repetitions: cardUpdates.repetitions,
        });
      } catch (error) {
        dispatch(
          setError("Umm...something went wrong. Please try refreshing the page")
        );
        console.error("ERROR: ", error);
      }
    });

    await Promise.all(updatePromises);
  };

  const handleClose = async () => {
    try {
      await updateSessionCards(cardsDue);
      setSessionId("");
      setSession([]);
      setCardsDue([]);
      onClose();
      const newRefreshTime = new Date().toISOString();
      dispatch(updateLastRefreshed(newRefreshTime));
      await sync();
    } catch (error) {
      dispatch(
        setError("An issue occurred while closing session. Please try again")
      );
      console.error("ERROR: ", error);
    }
  };

  const handleRating = async (rating) => {
    setIsReset(false);

    if (currentCard) {
      // Increment repetitions:
      let updatedRepetitions = currentCard.repetitions + 1;
      let updatedEaseFactor = currentCard.easeFactor;
      let updatedInterval = currentCard.interval;

      updatedEaseFactor += 0.1 - (5 - rating) * (0.08 + (5 - rating) * 0.02);
      updatedEaseFactor = Math.max(1.3, updatedEaseFactor);
      updatedInterval *= updatedEaseFactor;
      const intervalInMilliseconds = updatedInterval * 86400000;

      // Use the current date and time as the starting point for calculating the next review date.
      const now = new Date();
      const nextReviewAt = new Date(now.getTime() + intervalInMilliseconds);
      const updatedReviewAt = nextReviewAt.toISOString();

      // Update Card:
      let cardUpdates = [
        {
          cardId: currentCard.id,
          repetitions: updatedRepetitions,
          easeFactor: updatedEaseFactor,
          interval: updatedInterval,
          reviewAt: updatedReviewAt,
          difficultyRating: rating,
          isReviewed: true,
        },
      ];
      try {
        await updateSessionCard(sessionId, cardUpdates);
        const newSession = await findSessionById(sessionId);
        setCardsDue(newSession.cards);

        //Cleanup Functions:
        setShowBack(false);
        if (currentCardIndex === cardsDue.length - 1) {
          setIsSessionComplete(true);
        } else {
          handleNext();
        }
      } catch (error) {
        dispatch(
          setError("An issue occurred while handling rating. Please try again")
        );
        console.error("ERROR: ", error);
      }
    }
  };

  const DevInfoBox = () => {
    const prevCardIndex = currentCardIndex - 1;

    if (prevCardIndex >= 0) {
      const prevCard = cardsDue[prevCardIndex];
      if (!prevCard) {
        return cardsDue[currentCardIndex];
      }

      return (
        <Box
          sx={{
            position: "fixed",
            top: theme.spacing(2),
            left: theme.spacing(2),
            zIndex: 1500,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            maxWidth: "300px",
            overflow: "auto",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Previous Card Info
          </Typography>
          {Object.entries(prevCard).map(([key, value]) => (
            <Typography
              variant="caption"
              key={key}
              display="block"
              gutterBottom
            >
              {`${key}: ${
                typeof value === "object" && value !== null
                  ? JSON.stringify(value)
                  : value
              }`}
            </Typography>
          ))}
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {/* <DevInfoBox /> */}
      <AppDialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="info"
            onClick={handleInfoClick}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <InfoRoundedIcon />
          </IconButton>

          <IconButton
            disabled={isReset || currentCardIndex === 0}
            size="small"
            onClick={handlePrev}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>

          <IconButton
            disabled={
              !isPaused ||
              (!isPaused &&
                isSessionComplete &&
                currentCardIndex === cardsDue.length - 1)
            }
            size="small"
            onClick={handleNext}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          onClick={handleFlip}
          sx={{
            cursor: "pointer",
            minHeight: "auto",
            maxHeight: "calc(100vh - 96px)",
            overflow: "hidden",
            // border: "solid",
            // borderColor: "purple",
          }}
        >
          {isSessionComplete &&
          (currentCardIndex === cardsDue.length - 1 ||
            cardsDue.length === 0) ? (
            <>
              {/* Session Complete Card */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  my: 18.75,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ userSelect: "none", fontSize: 60, textAlign: "center" }}
                >
                  🎉
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ userSelect: "none", textAlign: "center" }}
                >
                  All Caught Up!
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ userSelect: "none", px: 2, mt: 1, textAlign: "center" }}
                >
                  You're all done for now. Cards will reappear as they're ready
                  for review.
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ userSelect: "none", px: 2, mt: 1, textAlign: "center" }}
                >
                  Learn more about{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Spaced_repetition"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Spaced Repetition
                  </a>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {/* Active Session Cards */}

              <Box
                sx={{
                  overflow: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                {showBack ? (
                  <Fade in={true} timeout={300}>
                    <div
                      dangerouslySetInnerHTML={{ __html: currentCard.sideB }}
                      className="ql-editor"
                      style={{
                        width: "100%",
                        padding: "16px",
                        boxSizing: "border-box",
                        wordBreak: "break-word",
                        textAlign: "left",
                        userSelect: "none",
                      }}
                    />
                  </Fade>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: currentCard.sideA }}
                    className="ql-editor"
                    style={{
                      width: "100%",
                      padding: "16px",
                      boxSizing: "border-box",
                      wordBreak: "break-word",
                      textAlign: "left",
                      userSelect: "none",
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions
          sx={{ justifyContent: "center", px: { xs: 2, sm: 0 }, pb: 3 }}
        >
          <Chip
            disabled={isSessionComplete || isPaused}
            icon={<CircleRoundedIcon />}
            variant="outlined"
            color="success"
            label="Easy"
            data-testid="easy-chip"
            onClick={() => handleRating(5)}
            sx={{
              "&&:hover": {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.white[50],
              },
            }}
          />

          <Chip
            disabled={isSessionComplete || isPaused}
            icon={<CircleRoundedIcon />}
            variant="outlined"
            color="secondary"
            label="Moderate"
            data-testid="moderate-chip"
            onClick={() => handleRating(4)}
            sx={{
              "&&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.white[50],
              },
            }}
          />

          <Chip
            disabled={isSessionComplete || isPaused}
            icon={<CircleRoundedIcon />}
            variant="outlined"
            color="warning"
            label="Hard"
            data-testid="hard-chip"
            onClick={() => handleRating(1)}
            sx={{
              "&&:hover": {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.white[50],
              },
            }}
          />

          <Chip
            disabled={isSessionComplete || isPaused}
            icon={<CircleRoundedIcon />}
            variant="outlined"
            color="error"
            label="Very Hard"
            data-testid="veryHard-chip"
            onClick={() => handleRating(0)}
            sx={{
              "&&:hover": {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.white[50],
              },
            }}
          />
        </DialogActions>

        <Popover
          open={infoOpen}
          anchorEl={infoAnchorEl}
          onClose={handleInfoClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h6"
              color="inherit"
              style={{ fontSize: 14, display: "flex", alignItems: "center" }}
            >
              <CircleRoundedIcon
                sx={{ color: "success.main", marginRight: 1 }}
              />
              Easy
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              You recalled the answer quickly and easily.
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              See this card again after a long while.
            </Typography>

            <Typography
              variant="h6"
              color="inherit"
              style={{ fontSize: 14, display: "flex", alignItems: "center" }}
            >
              <CircleRoundedIcon
                sx={{ color: "secondary.main", marginRight: 1 }}
              />
              Moderate
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              You recalled the answer with some hesitation.
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              See this card again after a moderate interval.
            </Typography>

            <Typography
              variant="h6"
              color="inherit"
              style={{ fontSize: 14, display: "flex", alignItems: "center" }}
            >
              <CircleRoundedIcon
                sx={{ color: "warning.main", marginRight: 1 }}
              />
              Hard
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              You struggled but eventually recalled the answer.
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              See this card again soon.
            </Typography>

            <Typography
              variant="h6"
              color="inherit"
              style={{ fontSize: 14, display: "flex", alignItems: "center" }}
            >
              <CircleRoundedIcon sx={{ color: "error.main", marginRight: 1 }} />
              Very Hard
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              You couldn't recall the answer or it took a lot of effort.
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontSize: 12 }}
            >
              See this card in a few minutes.
            </Typography>

            {/* Add more detailed information here */}
          </Box>
        </Popover>
      </AppDialog>
    </>
  );
};

export default StudyCards;
