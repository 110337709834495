/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { isTokenValid } from "../utilities/validateToken";

export const useAuth = () => {
  const tokenExpiration = useSelector((state) => state.auth.tokenExpiration);
  let isAuthenticated;

  try {
    isAuthenticated = isTokenValid(tokenExpiration);
  } catch (error) {
    console.error("Error occurred while checking authentication status:", error);
    isAuthenticated = false;
  }

  return { isAuthenticated };
};
