import db from "../database/db";

export const clearDb = async () => {
  try {
    // Clear the Dexie database
    await db.transaction("rw", db.tables, async () => {
      await Promise.all(db.tables.map((table) => table.clear()));
    });
  } catch (error) {}
};
