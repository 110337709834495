import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  Typography,
} from "@mui/material";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import Papa from "papaparse";
import { findOrCreateDeck } from "../../services/data/deckDataService";
import { createCard } from "../../services/data/cardDataService";
import { useSync } from "../../hooks/sync";
import { setError } from "../../redux/features/error/errorSlice";
import * as asvabDecks from "../../database/asvabDecks";

const SharedDeck = ({ open, onClose, onCloseComplete }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const sync = useSync();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
    if (onCloseComplete) onCloseComplete();
  };

  const handleUploadSubmit = async () => {
    setIsLoading(true);
    try {
      // Iterate over each deck in the imported asvabDecks
      for (const deckKey in asvabDecks) {
        const deckContent = asvabDecks[deckKey].trim();
        if (!deckContent) continue; // Skip if the deck is empty

        Papa.parse(deckContent, {
          header: true,
          skipEmptyLines: true,
          complete: async (results) => {
            const decksMap = new Map();
            for (const row of results.data) {
              const { DeckName, Front, Back } = row;
              if (!DeckName || !DeckName.trim()) {
                continue;
              }

              if (!decksMap.has(DeckName)) {
                const deckId = await findOrCreateDeck(DeckName, userId);
                decksMap.set(DeckName, deckId);
              }

              const deckId = decksMap.get(DeckName);
              await createCard({ sideA: Front, sideB: Back }, deckId, userId);
            }

            await sync();
          },
          error: (error) => {
            dispatch(
              setError(
                "Couldn't parse the file. Please make sure the file is a .csv and uses the provided template."
              )
            );
          },
        });
      }
      setIsLoading(false);
      handleClose();
    } catch (error) {
      dispatch(
        setError("Something went wrong. Please try refreshing the page.")
      );
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      data-testid="upload-deck-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: -30, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          borderRadius: 3,
        },
      }}
    >
      <DialogTitle>Shared Decks</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Load predefined ASVAB decks directly into your app.
        </Typography>

        <Box textAlign="center" my={2}>
          <Box my={4}>
            <LayersRoundedIcon fontSize="large" color="primary" />
            <Typography variant="subtitle1" gutterBottom>
              Load ASVAB Decks
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button
          onClick={handleUploadSubmit}
          variant="contained"
          color="primary"
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Load ASVAB Decks"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharedDeck;
