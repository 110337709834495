import db from "../../database/db";
import { v4 as uuidv4 } from "uuid";

export const createDeck = async (deck, userId) => {
  try {
    const now = new Date().toISOString();
    deck.cards = deck.cards || [];
    deck.createdBy = userId;
    deck.createdOn = now;
    deck.studyMode = deck.studyMode || "cram";
    deck.easeFactor = deck.easeFactor !== undefined ? deck.easeFactor : 1.3;
    deck.interval = deck.interval !== undefined ? deck.interval : 0.01;
    deck.deletedOn = deck.deletedOn || null;
    deck.description = deck.description || "";
    deck.id = uuidv4();
    deck.isDeleted = deck.isDeleted || false;
    deck.modifiedOn = deck.modifiedOn || now;
    deck.name = deck.name || "New Deck";
    deck.reviewAt = deck.reviewAt || now;
    deck.reviewedOn = deck.reviewedOn || now;
    deck.syncedOn = deck.syncedOn || null;

    const newDeckId = await db.decks.add(deck);
    return newDeckId;
  } catch (error) {
    console.error("Error creating deck:", error);
    return null; // Indicate failure by returning null
  }
};

export const findAllDecksByUserId = async (userId, includeDeleted = false) => {
  if (!userId) return [];

  try {
    const filterCondition = includeDeleted
      ? (deck) =>
          deck.createdBy === userId &&
          deck.isDeleted === false &&
          deck.deletedOn !== null
      : (deck) =>
          deck.createdBy === userId &&
          deck.isDeleted === false &&
          (deck.deletedOn === undefined || deck.deletedOn === null);

    const decks = await db.decks.filter(filterCondition).toArray();

    decks.sort((a, b) => a.name.localeCompare(b.name));

    return decks;
  } catch (error) {
    console.error("Error loading decks from IndexedDB:", error);
    return [];
  }
};

export const findOrCreateDeck = async (deckName, userId) => {
  try {
    let deck = await db.decks
      .where({ name: deckName, createdBy: userId })
      .and(
        (deck) =>
          !deck.isDeleted &&
          (deck.deletedOn === undefined || deck.deletedOn === null)
      )
      .first();

    if (!deck) {
      // Deck not found, create a new one and get its ID
      const newDeckId = await createDeck({ name: deckName }, userId);
      return newDeckId;
    } else {
      return deck.id;
    }
  } catch (error) {
    console.error("Error in findOrCreateDeck:", error);
    return null;
  }
};

export const findDeckById = async (deckId) => {
  if (!deckId) return null;

  try {
    const deck = await db.decks.get({ id: deckId });
    if (!deck) {
      console.error("Deck not found:", deckId);
      return null;
    }
    return deck;
  } catch (error) {
    console.error("Error fetching deck from IndexedDB:", error);
    return null;
  }
};

export const updateDeck = async (deckId, updates) => {
  try {
    const deck = await db.decks.get({ id: deckId });
    if (!deck) {
      console.error("Deck not found:", deckId);
      return null;
    }

    const updatedFields = {
      ...updates,
      modifiedOn: new Date().toISOString(),
    };

    await db.decks.update(deckId, updatedFields);
    return await db.decks.get({ id: deckId });
  } catch (error) {
    console.error("Error updating deck in IndexedDB:", error);
    return null;
  }
};

export const migrateDecks = async () => {
  try {
    const allDecks = await db.decks.toArray();
    let needsUpdateCount = 0;

    const updatedDecks = allDecks.map((deck) => {
      const hasDefaultInterval = "interval" in deck;
      const hasDefaultEaseFactor = "easeFactor" in deck;

      if (!hasDefaultInterval || !hasDefaultEaseFactor) {
        needsUpdateCount++;
        return {
          ...deck,
          interval: deck.interval || 0.01,
          easeFactor: deck.easeFactor || 1.3,
        };
      } else {
        return deck;
      }
    });

    if (needsUpdateCount > 0) {
      await db.decks.bulkPut(updatedDecks);
    }
  } catch (error) {
    console.error("Error in migrateDecks:", error);
  }
};
