import React from "react";
import { Box, Paper, Typography, useTheme } from "@mui/material";

const PaperListPlaceholder = ({ icon: Icon, primaryText, secondaryText }) => {
  const theme = useTheme();
  return (
    <Paper
      data-testid="paper-list-placeholder"
      elevation={0}
      sx={{
        borderRadius: 3,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 300,
      }}>
      <Box
        sx={{
          textAlign: "center",
        }}>
        {Icon && <Icon sx={{ fontSize: 40, color: theme.palette.gray.main }} />}
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">{primaryText}</Typography>
          {secondaryText && (
            <Typography p={2} variant="body2" color="text.secondary">
              {secondaryText}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default PaperListPlaceholder;
